import { httpRequest } from '../utils';

export interface SentimentGraphResponseType {
  CommentSentiment: number;
  RatingSentiment: number;
}

export const getSentimentGraphDataForPastXMin = async (
  sessionId: string,
  minutes: number
): Promise<SentimentGraphResponseType | undefined> => {
  return await httpRequest(
    {
      url: `https://carbon-sentiments-api-prod.azurewebsites.net/api/sentiments?SessionID=${sessionId}&Interval=${minutes}`,
      method: 'GET'
    },
    false
  );
};
