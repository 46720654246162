/* eslint-disable @typescript-eslint/no-floating-promises */

import { BASE_URL, TEST_URL } from '../http/api/services';
import { getLocalStorageItem, setLocalStorageItem } from '../utils';
import * as signalR from '@microsoft/signalr';
import { processCommand } from './process-commands';
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type

export const commonGroupId = '1234567699';
let groupId = '1234567890';
let hubCon: any = null;

export const createMySocketMiddleware = (url: any): any => {
  // let hubConInternal: any;
  // { on: (arg0: string, arg1: (message: any) => void) => void; send: (arg0: any) => void; };

  return (storeAPI: { dispatch: (arg0: { type: string; payload: any }) => void }) => {
    // createMyWebsocket(url, storeAPI);
    //  waitForSocketConnection(hubCon, () => {

    //   hubCon.on ('ReceiveCommand', (req:any) => {
    //     console.log('cmd received',req);

    //     if (req.command === '[HEY]'){
    //       storeAPI.dispatch({
    //         type : "SET_CHAT",
    //         payload : req.message
    //       });
    //       //  || req.command === '[SWITCH-TO-REMOTE-CAM]' || req.command === '[SWITCH-SHAREDWINDOW]') {
    //       // console.log(req);
    //       // this.$store.commit('setCheckFlag', true);
    //       // this.$store.commit('setWinShareFlag', true);
    //       // this.$store.commit('setSwitchInitiated', true);
    //     }
    //     // this.$store.dispatch ('processSwitchCommandFromHub', { message: req.command, data: req.message });
    //   });
    // });

    return (next: (arg0: any) => any) => async (action: { type: any; payload: any }) => {
      switch (action.type) {
        case 'LOGIN': {
          // socket =
          // await
          // createMyWebsocket(url);

          // socket.on("message", (message: any) => {
          //     storeAPI.dispatch({
          //         type : "SOCKET_MESSAGE_RECEIVED",
          //         payload : message
          //     });
          // });
          break;
        }
        case 'SEND_WEBSOCKET_MESSAGE': {
          // hubCon .invoke ('SendCommand', { command: action.payload }).then (() => {
          // waitForSocketConnection(socket, () => {
          //     socket.send(action.payload);
          // })

          break;
        }
        case 'SET_FLAG': {
          console.log('set flag called from middleware', action.payload);
          hubCon
            .invoke('SendCommandToGroup', {
              Command: '[HEY]',
              GroupId: groupId,
              Message: action.payload,
              toCliendId: 'hello',
              Who: 'ele'
            })
            .then(() => {
              console.log('command sent');
            });
          break;
        }
        // case '[MUTE_ALL]': {
        //   console.log('mute all called from middleware', action.payload);
        //   hubCon
        //     .invoke('SendCommandToGroup', {
        //       Command: '[MUTE_ALL]',
        //       GroupId: roomId,
        //       Message: action.payload
        //     })
        //     .then(() => {
        //       console.log('command sent');
        //     });
        //   break;
        // }
        case 'DESTROY_HUB_CON': {
          if (hubCon !== null) {
            const hubConCopy = hubCon;
            hubCon = null;
            await hubConCopy.stop();
          }
          break;
        }
        case 'CONNECT_TO_GROUP': {
          if (hubCon !== null) {
            const hubConCopy = hubCon;
            hubCon = null;
            await hubConCopy.stop();
          }
          groupId = action.payload.groupId;
          await createMyWebsocket(url, storeAPI, action.payload.role);
          // await connectToGroup(action.payload.groupId);
          break;
        }
        case 'SEND_COMMAND_TO_GROUP': {
          if (hubCon === null) {
            break;
          }
          console.log('send command called from middleware', action.payload);
          hubCon
            .invoke('SendCommandToGroup', {
              Command: action.payload.command,
              GroupId: action.payload.groupId ?? groupId,
              Message: action.payload.message
            })
            .then(() => {
              console.log('command sent');
            });
          break;
        }
      }

      return next(action);
    };
  };
};
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function waitForSocketConnection(socket: any, callback: (() => void) | null) {
  setTimeout(function () {
    console.log(socket);
    // if (socket.readyState === 1) {
    //   if (callback != null) {
    //     callback();
    //   }
    // } else {
    //   console.log("waiting for socket connection...")
    waitForSocketConnection(socket, callback);
    // }
  }, 100);
}

async function createMyWebsocket(url: any, storeAPI: any, role: string): Promise<any> {
  authenticate(role).then(() => {
    createHubConnection(storeAPI).then(async () => {
      await connectToGroup();
      storeAPI.dispatch({
        type: 'SET_HUB_CONNECTED',
        payload: true
      });

      // setInterval (this.oneSecondUpdate, 1000);

      // setInterval (this.fourSecondsUpdate, 4000);
    });
  });
  // .catch ((err) => {

  //     console.error (err.toString ());
  //   });
}

const settings = {
  API_URL: 'https://mgmt.prod.forum360.co/api',
  AuthURL: 'https://mhub.prod.forum360.co/api/authentication/Authenticate',
  ConnectivityTestURL: 'https://mgmt.prod.forum360.co',
  HubURL: 'https://mhub.prod.forum360.co/mainHub'
};

// const settings = {
//   API_URL: BASE_URL,
//   AuthURL: `${BASE_URL}/authentication/Authenticate`,
//   ConnectivityTestURL: TEST_URL,
//   HubURL: `${TEST_URL}/mainHub`
// };

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const authenticate = async (role: string) => {
  // TODO: Remove this hardcoded settings!
  let data;
  if (role === 'host') {
    data = {
      Secret:
        'dDJwMDRrRXFrdHpJU2NUTnNlamVyNWFaNnRrSGRKQmxjTXFFTEJCYTZTY2toblBVYnpjVnd1SFp3dFUycTVO',
      Username: 'p1',
      Password: 'p4343434325',
      ChathubURL: settings.HubURL
    };
  } else {
    data = {
      Secret:
        'dDJwMDRrRXFrdHpJU2NUTnNlamVyNWFaNnRrSGRKQmxjTXFFTEJCYTZTY2toblBVYnpjVnd1SFp3dFUycTVO',
      Username: 'p2',
      Password: 'p59487875',
      ChatHubURL: settings.HubURL
    };
  }

  const jsonData = JSON.stringify(data);
  try {
    const rawResponse = await fetch(settings.AuthURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: jsonData
    });
    const hubToken = await rawResponse.json();

    // this.$store.commit ('setHubToken', hubToken);
    setLocalStorageItem('hubToken', hubToken);
  } catch {
    console.log('error connecting to hub');
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
async function startCon(storeAPI: any) {
  try {
    await hubCon.start();
    console.log('SignalR Re Connected.');
    await connectToGroup(groupId);
    storeAPI.dispatch({
      type: 'SET_HUB_CONNECTED',
      payload: true
    });
  } catch (err) {
    console.log(err);
    if (hubCon.state === signalR.HubConnectionState.Disconnected) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      setTimeout(startCon, 5000);
    }
  }
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
async function createHubConnection(storeAPI: any) {
  const hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(settings.HubURL, {
      accessTokenFactory: () => getLocalStorageItem('hubToken')
    })
    .configureLogging(signalR.LogLevel.Error)
    // .withAutomaticReconnect()
    .build();

  if (hubConnection === null) {
    // !hubConnection
    return;
  }

  // this.$store.commit ('setHubConnection', hubConnection);
  // setLocalStorageItem('hubConnection', hubConnection);
  // store the connection in the store ?

  // where to store the connection ?
  hubCon = hubConnection;
  // hubConInternal = hubConnection;

  hubConnection.onclose((error: any) => {
    console.assert(hubConnection.state === signalR.HubConnectionState.Disconnected);
    console.log('on close called');
    storeAPI.dispatch({
      type: 'SET_HUB_CONNECTED',
      payload: false
    });
    if (hubCon !== null) {
      startCon(storeAPI);
    }
    console.log('error', error);
    // console.log(
    //   `Connection closed due to error "${
    //     error?.message ?? ''
    //   }". Try refreshing this page to restart the connection.`
    // );
  });

  hubConnection.onreconnected(() => {
    console.log('Hub Connection reestablished. Reconnecting to the group.');
    console.log('reborn');
    storeAPI.dispatch({
      type: 'SET_HUB_CONNECTED',
      payload: true
    });
    connectToGroup();
  });

  hubConnection.on('ReceiveCommand', (req) => {
    console.log('cmd received', req);
    if (req.command === '[HEY]') {
      if (req.message === 'toggle') {
        storeAPI.dispatch({
          type: 'SET_TOGGLE',
          payload: true
        });
        return;
      }
      storeAPI.dispatch({
        type: 'SET_CHAT',
        payload: req.message
      });
      return;
    }
    if (req.command === '[MUTE_ALL]') {
      storeAPI.dispatch({
        type: '[RECEIVE_MUTE_ALL]',
        payload: req.message.toLowerCase() === 'true'
      });
      return;
    }

    if (
      req.command === '[SWITCH-CAMERA]' ||
      req.command === '[SWITCH-TO-REMOTE-CAM]' ||
      req.command === '[SWITCH-SHAREDWINDOW]'
    ) {
      // console.log(req);
      // this.$store.commit('setCheckFlag', true);
      // this.$store.commit('setWinShareFlag', true);
      // this.$store.commit('setSwitchInitiated', true);
      return;
    }
    processCommand(storeAPI, req);
    // this.$store.dispatch ('processSwitchCommandFromHub', { message: req.command, data: req.message });
  });

  hubConnection.on('ReceiveDisconnection', (req) => {
    if (req.who === 'Producer') {
      // this.fetchVideoConnectorDisconnect(true);
      console.log('receive disconnection', req);
      storeAPI.dispatch({
        type: 'SET_PRODUCER_CONNECTED',
        payload: false
      });
    }
    // void hubConnection.stop();
    // }
  });

  // hubConnection.serverTimeoutInMilliseconds = 60000;

  await hubConnection
    .start()
    .then(() => {
      console.assert(hubConnection.state === signalR.HubConnectionState.Connected);
      console.log('connection obj', hubConnection);

      console.log('Hub Connection: Connected');
    })
    .catch((err: any) => {
      console.error(err.toString());
    });

  // setInterval(() => {
  //   hubConnection.state === signalR.HubConnectionState.Connected
  //     ? console.log('connected')
  //     : console.log('disconnected');
  // }, 3000);
  // return hubConnection;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
async function connectToGroup(id?: string) {
  // const groupId = this.$store.state.videoBox.userConnectData.resourceId;
  const groupIdLocal = id ?? groupId;

  console.log(`Connecting to Hub group: ${groupIdLocal}`);
  if (hubCon === null) {
    console.error('cannot connect to group because hub connection is stopped');
    return;
  }
  // this.$store.state.videoBox.hubConnection.invoke ('Connect', { groupId }).then (() => {
  hubCon
    .invoke('Connect', { groupId: groupIdLocal })
    .then(() => {
      // console.assert (this.$store.state.videoBox.hubConnection.state === signalR.HubConnectionState.Connected);

      console.log('Hub Connection: Connected to Group');
      // hubCon.invoke('SendCommandToGrou')
    })
    .catch((err: any) => {
      console.log(groupIdLocal);
      console.error(err.toString());
    });

  hubCon
    .invoke('Connect', { groupId: commonGroupId })
    .then(() => {
      // console.assert (this.$store.state.videoBox.hubConnection.state === signalR.HubConnectionState.Connected);

      console.log('Hub Connection: Connected to Group', commonGroupId);
      // hubCon.invoke('SendCommandToGrou')
    })
    .catch((err: any) => {
      console.log(commonGroupId);
      console.error(err.toString());
    });
}
